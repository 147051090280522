import React, { useState } from 'react';
import photo_mission from './../img/main_photo.jpg';
import photo_mission1 from './../img/main_photo1.jpg';
import photo_mission2 from './../img/main_photo2.jpg';
import photo_mission3 from './../img/main_photo3.jpg';
import photo_mission4 from './../img/main_photo4.jpg';
import photo_mission5 from './../img/main_photo5.jpg';
import photo_mission6 from './../img/main_photo6.jpg';
import photo_mission7 from './../img/main_photo7.jpg';
import photo_mission8 from './../img/main_photo8.jpg';

export default function Header() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const photos = [
    photo_mission,
    photo_mission1,
    photo_mission2,
    photo_mission3,
    photo_mission4,
    photo_mission5,
    photo_mission6,
    photo_mission7,
    photo_mission8
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === photos.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? photos.length - 1 : prevSlide - 1));
  };

  return (
    <div className='mission'>
      <div className='container'>
      <h2 className="photos-title">Фотографії</h2>
      <br /> 
        <div className='mission_img'>
          <button onClick={prevSlide} className="slide-button left-button">&#9664;</button>
          <img src={photos[currentSlide]} alt={`Mission Photo ${currentSlide + 1}`} className="current-photo" style={photos[currentSlide] === photo_mission || photos[currentSlide] === photo_mission3 ? { maxWidth: '100%', maxHeight: '100%' } : {}} />
          <button onClick={nextSlide} className="slide-button right-button">&#9654;</button>
        </div>
              
      </div>
      <br /> 
    </div>

  );
}
