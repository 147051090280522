import React from 'react';
import photo_mission from './../img/Certificate.pdf';
import photo_mission2 from './../img/IMG_0001.pdf';
import photo_mission3 from './../img/IMG_0002.pdf';
import photo_mission4 from './../img/certificate4.pdf';
import photo_mission5 from './../img/certificate5.pdf';
import photo_mission6 from './../img/certificate6.pdf';

export default function Header() {
  const certificates = [
    photo_mission4,
    photo_mission2,
    photo_mission,
    photo_mission3,
    photo_mission5,
    photo_mission6,
  ];

  const [currentCertificate, setCurrentCertificate] = React.useState(0);

  const nextCertificate = () => {
    setCurrentCertificate((currentCertificate + 1) % certificates.length);
  };

  const prevCertificate = () => {
    setCurrentCertificate((currentCertificate - 1 + certificates.length) % certificates.length);
  };

  return (
    <div>
      <div className='certificate_text'>
        <h2>Наші сертифікати:</h2>
        <br />
      </div>
      <div className='certificate_slider'>
        <button className="slide-button left-button" onClick={prevCertificate}>
          <span>&#8249;</span>
        </button>
        <object
          data={certificates[currentCertificate]}
          type="application/pdf"
          width="500"
          height="600"
        >
          <p>Ваш браузер не підтримує перегляд PDF-файлів.</p>
        </object>
        <button className="slide-button right-button" onClick={nextCertificate}>
          <span>&#8250;</span>
        </button>
      </div>

      <style>{`
        .certificate_slider {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px 0;
        }

        .slide-button {
          background-color: rgba(255, 255, 255, 0.8);
          border: none;
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease;
        }

        .slide-button:hover {
          background-color: rgba(255, 204, 60, 0.95);
          color: #333;
        }

        .left-button {
          position: absolute;
          left: 20px;
        }

        .right-button {
          position: absolute;
          right: 20px;
        }
      `}</style>
    </div>
  );
}