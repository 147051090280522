import React, { useState } from 'react';
import photosss from "./../img/photoss.jpg";
import './Tenderpack.css'; // Імпортуйте файли стилів для компонента

export default function Tenderpack() {
  const [showPopup, setShowPopup] = useState(false);

  const redirectToTender = () => {
    setShowPopup(true); // Показати спливаюче вікно
  };

  const closePopup = () => {
    setShowPopup(false); // Закрити спливаюче вікно
  };

  return (
    <div className='characteristic'>
      <div className='characteristic_img'>
        <img src={photosss} alt="Tender" className='tender-image' />
      </div>
      <div className='characteristic_text'>
        <p>
          <span className='characteristic_text_title'>ТОВ «ВКФ ВЕЛИКОМ» оголошує тендер на закупівлю обладнання</span>
          <br />
          ТОВ «ВКФ ВЕЛИКОМ» в рамках Проекту «Розвиток заводу рафінації та дезодорації олії», що співфінансується Агенством США з міжнародного розвитку (USAID) оголошує відкритий тендер на закупівлю обладнання.
          <br />
          <span className='characteristic_text_subtitle'>Дата оголошення тендеру:</span> 03.06.2024
          <br />
          <span className='characteristic_text_subtitle'>Термін подання тендерних пропозицій:</span> 10.06.2024
        </p>
        <p>
          <span className='characteristic_text_title'>Інформація про організатора тендеру</span>
          <br />
          ТОВ «ВКФ ВЕЛИКОМ» (ЄДРПОУ 35027102). Основний вид діяльності підприємства – рафінація та дезодорація. Ми маємо власні виробничі потужності.
        </p>
        <p>
          <span className='characteristic_text_title'>Предмет закупівлі</span>
          <br />
          Предметом закупівлі є обладнання, перелічене у файлі ТОВ «ВКФ ВЕЛИКОМ» (Додаток до тендерного оголошення)....
        </p>
        <button onClick={redirectToTender} className='read-more-button'>Читати більше</button>

        {/* Спливаюче вікно */}
        {showPopup && (
          <div className='overlay'>
            <div className='popup-content'>
<span className='close-button' onClick={closePopup}>Закрити</span>

              <p>
                ТОВ «ВКФ ВЕЛИКОМ» оголошує тендер на закупівлю обладнання в рамках Проекту «Розвиток заводу рафінації та дезодорації олії», що співфінансується Агенством США з міжнародного розвитку (USAID).
                <br />
                <span className='characteristic_text_subtitle'>Дата оголошення тендеру:</span> 03.06.2024
                <br />
                <span className='characteristic_text_subtitle'>Термін подання тендерних пропозицій:</span> 10.06.2024
                <br /><br />
                <span className='characteristic_text_title'>Інформація про організатора тендеру</span>
                <br />
                ТОВ «ВКФ ВЕЛИКОМ» (ЄДРПОУ 35027102). Основний вид діяльності підприємства – рафінація та дезодорація. Ми маємо власні виробничі потужності.
                <br /><br />
                <span className='characteristic_text_title'>Предмет закупівлі</span>
                <br />
                Предметом закупівлі є обладнання, перелічене у файлі ТОВ «ВКФ ВЕЛИКОМ» (Додаток до тендерного оголошення).
                <br /><br />
                Перелік наведеного обладнання є орієнтовний та наведений організатором для інформування учасників тендеру (постачальників) про потреби організатора (покупця). У випадку відсутності на ринку або у конкретного учасника тендеру (постачальника обладнання) частини із зазначених одиниць обладнання, заявлені на тендер одиниці обладнання можуть бути замінені на аналогічні за технічними характеристиками (або мати технічні характеристики не менше ніж у заявлених організатором на тендер одиниць обладнання).
                <div className='tenderpack'>
  <h2>ПЕРЕЛІК, ХАРАКТЕРИСТИКА ОБЛАДНАННЯ ДЛЯ ЗБІЛЬШЕННЯ ПРОДУКТИВНОСТІ ДО 180 ТОН НА ДОБУ</h2>
  <table class="equipment-table">
    <thead>
      <tr>
        <th>№</th>
        <th>Назва обладнання</th>
        <th>Кількість одиниць</th>
        <th>Опис та технічні характеристики обладнання</th>
      </tr>
    </thead>
    <tbody>
      <tr>
      <td>1</td>
        <td>Теплообмінник AlfaLaval Тип - пластинчастий</td>
        <td>1</td>
        <td>Країна виробник (Данія). Призначення: Нагрівання олії паром. Можливість додавання пластин</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Змішувач М7н Тип – динамічний</td>
        <td>1</td>
        <td>(«ТАН», Україна) (заміна статичної частини)</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Теплообмінник Т9.2н Тип – пластинчастий</td>
        <td>1</td>
        <td>Виробник AlfaLaval (Данія) Охолодження олії маслом</td>
      </tr>
      <tr>
        <td>4</td>
        <td>Теплообмінник AlfaLaval Т10.2н Тип - пластинчастий</td>
        <td>1</td>
        <td>Виробник AlfaLaval (Данія) Охолодження олії водою. Додавання пластин</td>
      </tr>
      <tr>
        <td>5</td>
        <td>Змішувач М14н Тип – динамічний</td>
        <td>1</td>
        <td>(«ТАН», Україна) (заміна статичної частини)</td>
      </tr>
      <tr>
        <td>6</td>
        <td>Насос Н16.2н Тип відцентровий</td>
        <td>1</td>
        <td>Виробник Lowara (Італія)</td>
      </tr>
      <tr>
        <td>7</td>
        <td>Теплообмінник Т17.2н Тип - пластинчастий</td>
        <td>1</td>
        <td>Виробник AlfaLaval (Данія) Нагрівання олії парою</td>
      </tr>
      <tr>
        <td>8</td>
        <td>Насос Н21.2н Тип – відцентровий</td>
        <td>1</td>
        <td>Для сепаратора С23.2н Тип - саморозвантажується (Китай)</td>
      </tr>
      <tr>
        <td>9</td>
        <td>Ємність Е24.2н Матеріал – Ст3 соапстоку Об'єм – 1 м3</td>
        <td>1</td>
        <td>(«ТАН», Україна)</td>
      </tr>
      <tr>
        <td>10</td>
        <td>Насос Н25.2н Тип – об'ємний</td>
        <td>1</td>
        <td>Україна</td>
      </tr>
      <tr>
        <td>11</td>
        <td>Насос Н13.2н Тип – дозатор</td>
        <td>1</td>
        <td>Виробник Lowara (Італія)</td>
      </tr>
      <tr>
        <td>12</td>
        <td>Насос Н21.2н Тип – відцентровий</td>
        <td>1</td>
        <td>Виробник Lowara (Італія)</td>
      </tr>
      <tr>
        <td>13</td>
        <td>Теплообмінник Т30.2н Тип – пластинчастий</td>
        <td>1</td>
        <td>Виробник AlfaLaval (Данія) Нагрівання олії парою</td>
      </tr>
      <tr>
        <td>14</td>
        <td>Змішувач М31.2н Тип – динамічний</td>
        <td>1</td>
        <td>(«ТАН», Україна) Матеріал – AISI 304</td>
      </tr>
      <tr>
        <td>15</td>
        <td>Сепаратор Тип - саморозвантажується</td>
        <td>2</td>
        <td>(Китай)</td>
      </tr>
      <tr>
        <td>16</td>
        <td>Насос Н4.2о Тип відцентровий</td>
        <td>1</td>
        <td>Виробник Lowara (Італія)</td>
      </tr>
      <tr>
        <td>17</td>
        <td>Фільтр Ф5.3о Тип – вертикальний пластинчастий</td>
        <td>1</td>
        <td>(«ТАН», Україна) ВНП-60</td>
      </tr>
      <tr>
        <td>18</td>
        <td>Фільтр Ф5.4о Тип – вертикальний пластинчастий</td>
        <td>1</td>
        <td>(«ТАН», Україна) ВНП-60</td>
      </tr>
      <tr>
        <td>19</td>
        <td>Змішувач М6о Тип – статичний</td>
        <td>1</td>
        <td>(«ТАН», Україна) Матеріал – AISI 304</td>
      </tr>
      <tr>
        <td>20</td>
        <td>Блок ПБ13.2о Двоступінчастий Пароежекторний з конденсатором змішування</td>
        <td>1</td>
        <td>( Німеччина)</td>
      </tr>
      <tr>
        <td>21</td>
        <td>Теплообмінник Т16о Тип – пластинчастий</td>
        <td>1</td>
        <td>Виробник AlfaLaval (Данія) Нагрівання олії пором Додавання пластин</td>
      </tr>
      <tr>
        <td>22</td>
        <td>Насос Н20о Тип відцентровий</td>
        <td>1</td>
        <td>Виробник Lowara (Італія)</td>
      </tr>
      <tr>
        <td>23</td>
        <td>Теплообмінник Т1в Тип – пластинчастий</td>
        <td>1</td>
        <td>Виробник AlfaLaval (Данія) Охолодження олії маслом Додавання пластин</td>
      </tr>
      <tr>
        <td>24</td>
        <td>Теплообмінник Т2в Тип – пластинчастий</td>
        <td>1</td>
        <td>Виробник AlfaLaval (Данія) Охолодження олії водою Додавання пластин</td>
      </tr>
      <tr>
        <td>25</td>
        <td>Кристалізатор Е5.2в Матеріал – Ст3</td>
        <td>1</td>
        <td>(«ТАН», Україна) Об'єм – 25 м куб Перемішуючий пристрій, змійовик</td>
      </tr>
      <tr>
        <td>26</td>
        <td>Експозитор Е6.2в Матеріал – Ст3</td>
        <td>1</td>
        <td>(«ТАН», Україна) Об'єм – 25 м куб Перемішуючий пристрій, змійовик</td>
      </tr>
      <tr>
        <td>27</td>
        <td>Насос Н8.2в Тип – відцентровий</td>
        <td>1</td>
        <td>Виробник Lowara (Італія)</td>
      </tr>
      <tr>
        <td>28</td>
        <td>Теплообмінник Т9.2в Нагрівання олії водою</td>
        <td>1</td>
        <td>(«ТАН», Україна)</td>
      </tr>
      <tr>
        <td>29</td>
        <td>Газодувка Г31.2в Тип – роторний компресор</td>
        <td>1</td>
        <td>Робуша, Німеччина</td>
      </tr>
      <tr>
        <td>30</td>
        <td>Машина ХМ15.2в Холодильна машина</td>
        <td>1</td>
        <td>(Україна/Німеччина) Компрессор - Bitzer</td>
      </tr>
      <tr>
        <td>31</td>
        <td>Насос Н20в Тип – відцентровий</td>
        <td>1</td>
        <td>Виробник Lowara (Італія)</td>
      </tr>
      <tr>
        <td>32</td>
        <td>Насос Н26.2в Тип – відцентровий Циркуляції води в Т9.2в</td>
        <td>1</td>
        <td>Виробник Lowara (Італія)</td>
      </tr>
      <tr>
        <td>33</td>
        <td>Градирня ежекторів Гр18.2а Тип - вентиляторна</td>
        <td>1 комплект</td>
        <td>Китай</td>
      </tr>
      <tr>
        <td>34</td>
        <td>Градирня чистої води Гр21.2а Тип - вентиляторна</td>
        <td>1 комплект</td>
        <td>Китай</td>
      </tr>
    </tbody>
  </table>
</div>
<div>
  <br /><br />
  <p><strong>Умови поставки:</strong></p>
  <p>На момент поставки (передачі у власність покупцю) обладнання повинне перебувати у власності постачальника, бути вільним від будь-яких обмежень з боку третіх осіб та обтяжень.</p>
  <br />
  <p><strong>Передача організатору (покупцю) обладнання здійснюється за адресою вказаною організатором (покупцем), як місце поставки, уповноваженій особі організатора (покупця). Право власності до організатора (покупця) переходить на підставі видаткової накладної та з дати її підписання.</strong></p>
  <br />
  <p><strong>Оплата за обладнання здійснюється покупцем (організатором) у національній валюті України – гривні або євро.</strong></p>
  <br />
  <p><strong>Термін виготовлення: до 01 червня 2025 р.</strong></p>
  <br />
  <p><strong>Місце поставки обладнання: 35641 с.Тараканів, Рівненська область, вул. Свободи 40.</strong></p>
  <br /><br />
  <p>До участі у тендері запрошуються постачальники обладнання, що реалізують обладнання на території України (будь-якої організаційної форми та права власності), що мають відповідний КВЕД та іноземні виробники.</p>
  <br />
  <p><strong>Істотні критерії (умови) відбору тендерних пропозицій:</strong></p>
  <ul>
    <li>Відповідність запропонованих учасником одиниць обладнання технічним характеристикам, яких потребує організатор.</li>
    <li>Прийнятна ціна, умови оплати та умови поставки.</li>
    <li>Відповідність поданих документів умовам тендерного оголошення.</li>
  </ul>
  <br /><br />
  <p>За результатами оцінки істотних критеріїв, організатор (покупець) відбере пропозицію з найменшою ціною серед тих, які відповідають технічним специфікаціям/вимогам без суттєвих відхилень.</p>
  <br /><br />
  <p><strong>Тендерні процедури</strong></p>
  <p>Запитання щодо тендеру можна надсилати в електронному вигляді за ел. адресою: velicom.vkf@gmail.com до 09.06.2024 р. (включно).</p>
  <p>До участі у відборі тендерних пропозицій допускаються тендерні пропозиції, які повністю відповідають умовам цього тендерного оголошення.</p>
  <p>Тендерні пропозиції, повинні бути чинними та мати строк дії не менше 30 днів з моменту подачі.</p>
  <p>Тендерні документи повинні бути складені українською мовою, підписані уповноваженою особою учасника та засвідчені печаткою (за наявності) та у відсканованому (електронному вигляді, у форматі PDF) надіслані учасником організатору тендеру до 09.05.2024 р. (включно) на електронну адресу velicom.vkf@gmail.com із зазначенням у темі ел. листа «Відкритий тендер: закупівля обладнання». Всі тендерні пропозиції, отримані організатором після кінцевого терміну їх подання, розгляду не підлягатимуть.</p>
  <br /><br />
  <p>Визначення переможця тендеру відбудеться організатором тендеру після фінансової та технічної оцінки запропонованих тендерних пропозицій згідно істотних критеріїв (умов) відбору, які зазначені тендерному оголошенні, але не пізніше 10.06.2024 року.</p>
  <p>Результати тендеру будуть повідомлені організатором учасникам тендеру шляхом надсилання відповідних повідомлень електронною поштою протягом не більше ніж трьох робочих днів з дати прийняття рішення про визначення переможців.</p>
  <br /><br />
  <p><strong>Додаткові застереження</strong></p>
  <p>Учасник цієї загальної процедури (відкритого тендеру) надсилаючи документи для участі у загальній процедурі (відкритому тендері) за цим тендерним оголошенням підтверджує та визнає, що несе відповідальність за достовірність наданої інформації та усвідомлює репутаційні ризики, пов’язані з наданням недостовірних відомостей та наслідки подання документів, що не відповідають умовам цього тендерного оголошення, свою безумовну та беззастережну згоду з усіма умовами тендеру і зобов’язується виконувати їх належним чином.</p>
  <br />
  <p>Учасник цієї загальної процедури (відкритого тендеру) надсилаючи документи для участі у загальній процедурі (відкритому тендері) за цим тендерним оголошенням підтверджує своє розуміння та згоду з тим, що організатор тендеру може відхилити його тендерну пропозицію у випадку, якщо пропозиції інших учасників міститимуть більш вигідні умови, та що організатор тендеру необмежений у прийнятті будь-якої іншої пропозиції з більш вигідними для нього умовами.</p>
  <br /><br />
  <p><strong>Контактна особа з боку організатора:</strong></p>
  <p>velicom.vkf@gmail.com</p>
  <p>тел. +38 98 553 82 31</p>
</div>

              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
