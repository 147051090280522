import React from "react";
import { SlArrowRight } from "react-icons/sl";
import logo from "./../img/vELIKOM.svg";

export default function Header() {
  return (
    <header className="header-wrapper">
      <div>
        <span className="logo">
          <h1>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              <img src={logo} alt="ВЕЛИКОМ" />
              ВЕЛИКОМ
            </a>
          </h1>
        </span>
      </div>
      <div className="buttons">
        <ul>
          <li>
            <p
              onClick={() => {
                window.scrollTo({
                  top: 3300,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              ФОТО
            </p>
          </li>
          <li>
            <p
              onClick={() => {
                window.scrollTo({
                  top: 2500,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              СЕРТИФІКАТИ
            </p>
          </li>
          <li>
            <p
              href="#"
              onClick={() => {
                window.scrollTo({
                  top: 1250,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              ПРОДУКЦІЯ
            </p>
          </li>
          <li>
            <p
              href="#"
              onClick={() => {
                window.scrollTo({
                  top: 4250,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              КОНТАКТИ
            </p>
          </li>
        </ul>
      </div>
    </header>
  );
}