import React from 'react';

import Characteristic_photo from "./../img/photo_2024-02-08_22-33-17.jpg"

export default function Characteristic() {
    return (
        <div className='characteristic'>
              <div className='characteristic_img'>
                <img src={Characteristic_photo} />
            </div>
            <div className='characteristic_text'>
                <p>
                    <span className='characteristic_text_title'>Характеристики:</span>
                    <br />
                    <br />
                    Склад: олія соняшникова рафінована дезодорована виморожена марки П
                    <br />
                    Енергетична цінність на 100 г продукту: 3761 кДж / 899 ккал
                    <br />
                    Поживна цінність на 100 г продукту: білки 0,0 г жири 99,9 г, - з них насичені жири 8,8-16,8г вуглеводи 0,0 г
                    <br />
                    Країна походження: Україна
                    <br />
                    Умови зберігання: за температури від 0 до +30 С
                    <br />
                    Термін придатності: 24 місяці
                </p>
            </div>
          
        </div>
    );
}