import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const Maps = () => {
  useEffect(() => {}, []);

  const center = [50.38153, 25.69923];

  const redIcon = new L.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <div
      className="map-wrapper"
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
        backgroundColor: "#ffac3c",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Тінь або обрамлення
      }}
    >
      <div id="mainmap" style={{ width: "80%", marginRight: "20px" }}>
        <MapContainer center={center} zoom={12} style={{ height: "290px", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="© OpenStreetMap contributors"
          />
          <Marker position={center} icon={redIcon}>
            <Popup>
              ТОВ "ВКФ "ВЕЛИКОМ"
              <br />
              35641, Рівненська обл., Дубенський р-н, с. Тараканів, вул. Свободи, 40
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      <div
        className="map-contacts"
        style={{
          width: "40%",
          fontFamily: "Arial, sans-serif",
          fontSize: "18px",
          paddingTop: "20px",
          paddingBottom: "20px",
          color: "#fff",
        }}
      >
        <h2 style={{ fontSize: "24px", marginBottom: "15px" }}>Контакти : </h2>
        <p>
          <strong>Назва компанії :</strong>
          <br />
          ТОВ "ВКФ "ВЕЛИКОМ"
        </p>
        <p>
          <strong>Адреса :</strong>
          <br />
          35641, Рівненська обл., Дубенський р-н, с. Тараканів, вул. Свободи, 40
        </p>
        <p>
          <strong>Телефон :</strong>
          <br />
          380985538231
        </p>
        <p>
          <strong>E-mail :</strong>
          <br />
          o.pidlisnyi@gmail.com
        </p>
      </div>
    </div>
  );
};

export default Maps;