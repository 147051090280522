import React from 'react';

export default function Header() {
    return (
        <div className='main_banner'>
            <div className='banner'>
                <div className='container'>
                    <div className='text_right'>
                        <h1 style={{ fontSize: '100px', textAlign: 'right' }}><p>Дбайливо відібрана,<br />ретельно рафінована.</p></h1>
                    </div>
                </div>
            </div>
        </div>
    );
}