import React from 'react';
import photo from './../img/a-large-sunflower-a-bottle-of-oil-seeds-on-the-t-2022-06-01-22-47-39-utc-scaled.jpg';

const History = () => {
  const productList = [
    'ОЛІЯ СОНЯШНИКОВА РАФІНОВАНА',
    'ЖИРНІ КИСЛОТИ',
    'СОАПСТОК'
  ];

  return (
    <div className="history">
      <div className="container">
        <div className="flex">
          <div className="history_text">
            <div className="top_text">
              <p className="display">КОМПАНІЯ «ВЕЛИКОМ» ВИРОБЛЯЄ ТАКУ ПРОДУКЦІЮ:</p>
            </div>
            <br /> 
            <div className="down_text">
              <ul>
                {productList.map((product, index) => (
                  <li key={index} className="product-item">
                    {product}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="image">
            <img src={photo} alt="Company History" className="img-fluid" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
