import React from 'react';
import Header from './component/Header';
import Main_page from './component/Main_page';
import Mission from './component/mission';
import Values from './component/Values';
import History from './component/History';
import Maps from './component/maps';
import Characteristic from './component/characteristic';
import Tenderpack from './component/Tenderpack';

function App() {
  return (
    <div className="app">
      <Header />
      <div className="wrapper">
        <Main_page />
        <History />
        <Characteristic />
        <Values />
        <Tenderpack />
        <Mission />
        <Maps />
      </div>
    </div>
  );
}

export default App;

